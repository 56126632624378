<template>
    <div
        class="relative flex flex-1"
    >
        <label
            for="search-field"
            class="sr-only"
        >Rechercher</label>
        <MagnifyingGlassIconOutline class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" />
        <input
            id="search-field"
            :value="registrationsQueryParameters.search"
            class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Rechercher..."
            type="search"
            name="search"
            @input="setSearch"
        >
        <div
            v-if="registrations?.length && registrationsQueryParameters.search"
            ref="searchResults"
            class="absolute left-0 z-20 bg-white border rounded-b-lg shadow md:left-auto w-96 top-full"
        >
            <div
                v-if="isLoading"
                class="h-10 flex items-center justify-center"
            >
                <span class="loading gray-dark" />
            </div>
            <ul class="max-h-[80vh] overflow-auto divide-y divide-gray-200">
                <li>
                    <button
                        v-for="registration in registrations"
                        :key="registration.uuid"
                        class="flex w-full p-4 text-left hover:bg-blue-50"
                        @click="goTo(registration)"
                    >
                        <UiAvatar
                            class="size-10"
                            :path="registration.baby.avatar"
                        />
                        <div class="flex-1 ml-3">
                            <div class="flex items-center justify-between">
                                <p class="text-sm font-medium text-gray-900">
                                    {{ registration.baby.name }}
                                </p>
                                <p class="hidden badge badge-bordered md:block">
                                    dossier {{ registration.reference }}
                                </p>
                            </div>
                            <p class="text-sm text-gray-500">
                                {{ $filters.age(registration.baby.date_of_birth) }}
                            </p>
                        </div>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { REGISTERED } from 'abcreche-constants/src/const/registrations/statuses.js'
import { debounce } from 'lodash'
import RegistrationRepository from '~/repositories/Creche/RegistrationRepository.js'
import registrationsListMixin from '~/mixins/registrations/registrationsListMixin.js'

export default defineComponent({
    name: 'CrecheTopSearch',

    mixins: [registrationsListMixin],

    methods: {
        setSearch (event) {
            this.registrationsQueryParameters.search = event.target.value

            this.searchRegistrations()
        },

        goTo (registration) {
            this.registrationsQueryParameters.search = ''
            this.$router.push(this.layoutPrefix + '/babies/' + registration.baby.uuid)
        },

        searchRegistrations: debounce(function (page = 1) {
            this.load()

            RegistrationRepository.index({
                ...this.registrationsQueryParameters,
                status: [REGISTERED],
                page
            })
                .then((response) => {
                    if (this.registrationsPaginationShouldAppend) {
                        this.registrations = this.registrations.concat(response.data)
                    } else {
                        this.registrations = response.data
                    }
                    this.registrationsPagination = response.meta
                    this.done()
                })
                .catch(() => {
                    this.$toast.error({
                        title: 'Erreur Serveur',
                        message: 'Il y a une erreur lors de la requête',
                        duration: 20000
                    })
                })
        }, 500)
    }
})
</script>
